import React from 'react'

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import naturally_image from '../../assets/naturally-image-min.png';

import naturally_photos_1 from '../../assets/naturally-photos-1-min.png';
import naturally_photos_2 from '../../assets/naturally-photos-2-min.png';
import naturally_photos_3 from '../../assets/naturally-photos-3-min.png';
import naturally_photos_4 from '../../assets/naturally-photos-4-min.png';
import naturally_photos_5 from '../../assets/naturally-photos-5-min.jpg';

import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor'


// import off2class_more_proj_image from '../../assets/off2class_more_projects_image.png'

// import noesis_more_proj_image from '../../assets/noesis_more_projects_image.png'
import { NextProjectSection, ProjectTitleSection, SectionTitle, SubsectionTitle } from '../../components/shared-proj-components';

function NaturallyProjectPage() {
  const { isMobile } = useIsMobile();

  return (
    <div style={{ backgroundColor: '#F6F7F9', overflow: 'hidden' }} className="app">
      <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />
      <Header/>
      <ProjectTitleSection
        color={'#000'}
        subtitleColor={'#193935'}
        title={"Naturally D'vine"} 
        subtitle={'PRODUCT PHOTOGRAPHY'} 
        date={'JUNE 2021'} 
        description={'Natural and organic beauty company based in Toronto'} 
        image={naturally_image}
        descriptionWidth={350}
      />
      <SectionTitle title='Design Process' color='#623D2E' />

      
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Introduction' color='#B59250' lineColor='#B59250' />        

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <span>Naturally D’vine is a black-owned business in Toronto that sells handmade organic beauty products for women everywhere. My role was to take photographs of their upcoming products for their website and social media platforms. <br/><br/></span>
          <span>Once I talked with the co-founder and learned more about their brand and vision for the company, I came up with a moodboard that would best represent their company’s values and mission statement. With that in mind, the photographs aimed to showcase the products outdoors to drive their point of natural and organic made beauty products.<br/><br/></span>
          <span>Here are the results.<br/><br/></span>
          <br/>
        </div>
      </div>
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Photography' color='#B59250' lineColor='#B59250' />        

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <img src={naturally_photos_4} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />

          <img src={naturally_photos_3} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />

          <img src={naturally_photos_2} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />

          <img src={naturally_photos_1} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />

          <img src={naturally_photos_5} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />
        </div>
      </div>

        <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#623D2E' />
        <NextProjectSection extra first='instacart' second='noesis' textColor='#623D2E' textColorMobile='#623D2E' />

        {/* <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }} />
          <div style={{ display: 'flex', flex: 4, flexDirection: 'row', ...style.atminIntroductionText}}>
              <a href="#/off2class" style={{ flex: isMobile ? '1 1 100%' : '1 1 45%', textDecoration: 'none', color: 'black', marginRight: isMobile ? 0 : 130 }}>
                <div style={isMobile ? { width: '100%', display: 'flex', justifyContent: 'center'} : {}}>
                  <img src={off2class_more_proj_image} style={{ borderRadius: 20, width: '100%', marginBottom: isMobile ? 12 : 26}} alt="instacart mockup" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', ...(isMobile && {flexDirection: 'row'})}}>
                    <div style={{...style.nextProjectTitle, ...(isMobile && mobileStyle.nextProjectTitle)}}>Off2Class</div>
                    <div style={{...style.nextProjectSubtitle, ...(isMobile && mobileStyle.nextProjectSubtitle)}}>{isMobile ? '|  Website' : 'Website'}</div>
                </div>
              </a>
              {!isMobile && <a href="#/noesis" style={{ flex: isMobile ? '1 1 100%' : '1 1 45%', textDecoration: 'none', color: 'black', marginTop: isMobile ? 85 : 0 }}>
                <div style={isMobile ? { width: '100%', display: 'flex', justifyContent: 'center'} : {}}>
                  <img src={noesis_more_proj_image} style={{ borderRadius: 20, width: '100%', marginBottom: 26}} alt="instacart mockup" />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', ...(isMobile && {alignItems: 'center', marginLeft: 0})}}>
                    <div style={{...style.nextProjectTitle, ...(isMobile && mobileStyle.nextProjectTitle)}}>Noesis</div>
                    <div style={{...style.nextProjectSubtitle, ...(isMobile && mobileStyle.nextProjectSubtitle)}}>Branding</div>
                </div>
              </a>}
            
          </div>
        </div> */}
     
      <Footer /> 
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    fontFamily: 'Roboto',
    color: '#535353', 
    fontWeight: 700,
    fontSize: 18
  },
  designProcessSubstep: {
    color: '#000000',
    fontSize: 18,
    marginBottom: 16,
  },
  mainBodyText: {
    color: '#000000',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 10% 50px 10%",
      paddingTop: 40,
  },
  subheadings: {
    color: '#193935',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 87px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 600,
    fontSize: 36,
    color: '#623D2E'
  },
  projectTitle: {
    fontSize: 64,
    color: '#B59250',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  projectSubtitle: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 96,
    width: '70%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 60,
  },
  container: {
    display: 'flex',
    margin: "152px 152px 100px 152px",
    justifyContent: 'space-between',
  },
  projectStepTitle: {
    fontSize: 24,
    color: '#B59250',
    fontWeight: 600
  },
  designProcessStep: {
    height: 50,
    color: '#202020',
    fontSize: 18,
    marginBottom: 16,
    fontWeight: 600,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#623D2E',
    marginLeft: 45,
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 24px 100px 24px",
    alignItems: 'center',
  },
  titleImageContainer: {
    display: 'flex', width: '50%', justifyContent: 'center', alignItems: 'center'
  },
  lineSection: { 
    margin: "0px 32px 87px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 87px 32px",
  },
  projectStepTitle: {
    fontSize: 18
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#623D2E', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#623D2E', 
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },
}

export default NaturallyProjectPage;
