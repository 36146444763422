import React, { useEffect, useState } from 'react';
import styles from './side-nav.module.css';
import useIsMobile from '../../hooks/useIsMobile';

const SideNav = ({ items, sectionsRef, textColor, defaultColor = 'gray' }) => {

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (window.innerWidth < 1660) {
    return  <></>;
  }

  const [currentSection, setCurrentSection] = useState('header');

  useEffect(() => {
    // Create an Intersection Observer instance
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.id);
        }
      });
    }, {
      threshold: 0.2, // Trigger when 50% of the section is in view
    });

    // Observe each section
    sectionsRef.current.forEach((section) => {
      observer.observe(section);
    });

    // Clean up observer on component unmount
    return () => {
        sectionsRef.current.forEach((section) => {
            if (section) {
                observer.unobserve(section);
            }
        });
    };
  }, []);

  return (
    <div className={styles.sideNav} style={{ opacity: currentSection === 'header' ? 0 : 1, transition: 'opacity 0.3s ease-in-out' }}>
      <ul>
        {items.map((item) => (
          <li key={item.id} style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ height: 3, width: 3, borderRadius: 3, backgroundColor: currentSection === item.id ? textColor : 'transparent', transition: 'background-color 0.3s ease-in-out' }}></div>
            <button style={{...{ color: textColor, fontSize: 13 }, ...(currentSection === item.id ? { fontSize: 15 } : { color: defaultColor })}} onClick={() => handleScroll(item.id)}>{item.title}</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideNav;