import { useState, useEffect } from 'react'

function useIsMobile() {
    const [width, setWindowWidth] = useState(0)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => { 
      updateDimensions();
      window.addEventListener("resize", updateDimensions);
      return () => 
        window.removeEventListener("resize", updateDimensions);
    }, [])

    const updateDimensions = () => {
      const width = window.innerWidth
      console.log(width);
      if (width < 897) {
        console.log('here')
        setIsMobile(true);
      } else { 
        console.log('here 2')
        setIsMobile(false);
      }
      setWindowWidth(width)
    }

    return { isMobile, width };
}

export default useIsMobile;
