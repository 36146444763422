import React, { useEffect, useState } from 'react';
import styles from './guest-area.module.css';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor';
import { useSearchParams } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';


const getQueryParamsFromHash = () => {
  const hash = window.location.hash.substring(1); // Remove the leading '#'
  const [path, queryString] = hash.split('?'); // Split path and query string
  const urlParams = new URLSearchParams(queryString); // Parse query string
  return {
    path,
    params: Object.fromEntries(urlParams.entries())
  };
};


const GuestArea = ({ onAuthenticate }) => {
    const { isMobile } = useIsMobile();
    const [password, setPassword] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onAuthenticate(password); // Pass the entered password to the parent component
    };
  
    const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const { params } = getQueryParamsFromHash();
    if (params.p) {
      onAuthenticate(params.p)
      localStorage.setItem('projects-pass', p);
    } else {
      let value = localStorage.getItem('projects-pass');
      if (value) {
        onAuthenticate(value)
      }
    }
  }, [searchParams]); // Empty dependency array ensures this runs only on component mount


  return (
    <div className={styles.guestArea}>
        <AnimatedCursor
            innerSize={5}
            outerSize={40}
            color='0, 0, 0'
            outerAlpha={0.2}
            innerScale={0.5}
            outerScale={1.5}
        />
      <div className={styles.modal}>
        <h2>Guest Area</h2>
        <p>Please enter the password below.</p>
        <a href="mailto:yaldasafarli@hotmail.com?subject=Request Portfolio Password" className={styles.requestAccess}>
          Request access here.
        </a>
        <form onSubmit={handleSubmit} style={{ ...(isMobile ? { alignItems: 'flex-end', display: 'flex', flexDirection: 'column' } : {}) }}>
          <input
            type="password"
            value={password}
            onChange={(e) => {
              localStorage.setItem('projects-pass', e.target.value);
              setPassword(e.target.value)
            }}
            placeholder="Password"
            className={styles.passwordInput}
            style={{ ...(isMobile ? { marginRight: 0, width: '90%' } : {}) }}
          />
          <button type="submit" className={styles.submitButton}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default GuestArea;
