import React, { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer'
import atmin_home_image from './assets/atmin-home-image.png';
import elentra_home_image from './assets/elentra/elentra-home-image.png'
import o2c_home_mockup from './assets/o2c-home-image.png';
import lock_image from './assets/lock.png';
import Footer from './components/footer/footer';
import Header from './components/header/header';

import './App.css';

import useIsMobile from './hooks/useIsMobile';
import AnimatedCursor from './components/animated-cursor/animated-cursor'
import Wave from 'react-wavify'
import FadeIn from './components/animations/FadeIn';
import TextLink from './components/text-link';

const ProjectComponent = ({ imagesrc, name, isMobile, role, date, description, link, type, viewProjectLink, lock }) => {

  return (
    <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', marginTop: 48, marginLeft: isMobile ? 24 : 152, marginRight: 24, marginBottom: 88}}>
      <div style={isMobile ? { width: '100%', display: 'flex', justifyContent: 'center', marginBottom: 24 } : { width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={imagesrc} style={{ width: isMobile ? '100%' : '100%', height: 'auto' }} alt={`${name} mockup`} />
      </div>
      <div style={{ marginTop: isMobile ? 0 : 0, width: isMobile ? '100%' : '50%', paddingLeft: isMobile ? 12 : 72, justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
        <div>
          <a href={link} style={{...styles.highlightedProjectTitle, marginRight: 12, ...(isMobile ? { fontSize: 24, marginBottom: 12 } : { })}}>{name}</a>
          {!!lock && <img src={lock_image} style={{ width: 20 }} alt={`${name} mockup`} />}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', color: '#623D2E', fontSize: 18, marginTop: 8}}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: 72, fontWeight: 500 }}>
            {!isMobile && <div style={{ marginBottom: 8 }}>Role</div>}
            {!isMobile && <div style={{ marginBottom: 8 }}>Date</div>}
            {!isMobile && <div>Type</div>}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 400}}>
            {!isMobile && <div style={{ marginBottom: 8 }}>{role}</div>}
            {!isMobile && <div style={{ marginBottom: 8 }}>{date}</div>}
            {!isMobile && <div>{type}</div>}
          </div>
        </div>
        <div style={{...styles.highlightedProjectSubtitle, color: '#343537', marginTop: isMobile ? 12 : 32, width: isMobile ? '90%' : '60%'}}>{description}</div>
        <div style={{ display: 'flex', alignSelf: 'start', marginTop: 36}}>
          <TextLink link={link}>View project</TextLink>
        </div>
      </div>
    </div>
  )
}


function Home() {
  const [inView1, setInView1] = useState(0)
  // const [inView2, setInView2] = useState(0)
  const [inView3, setInView3] = useState(0)

  const { isMobile, width } = useIsMobile();

  function hashLinkScroll() {
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#/#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }

  useEffect(() => {
    hashLinkScroll();
  }, [])

  return (
    <div style={styles.app}>
     
       <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />

      <Header />
      {!isMobile && <div style={{ display: 'flex', height: '90vh', marginBottom: '-90vh',  justifyContent: 'flex-end'}}>
        <Wave 
            fill='#f0ebe4'
            paused={false}
            options={{
              height: window.innerHeight * .75,
              amplitude: 55,
              speed: 0.18,
              points: 5
            }}
        />
      </div>}
      {!isMobile && <div style={{ display: 'flex', height: '90vh', marginBottom: '-90vh',  justifyContent: 'flex-end'}}>
        <Wave fill='#E8E1D6'
              paused={false}
              options={{
                height: window.innerHeight * .80,
                amplitude: 25,
                speed: 0.18,
                points: 3
              }}
        />
      </div>}

     
      <div style={{ ...styles.homeSection, ...(isMobile && mobileStyles.homeSection)}}>
        <div style={{...styles.name, ...(isMobile && mobileStyles.name)}} />
        <FadeIn timeout={300}><div style={{...styles.mainTitleFirst, ...(isMobile && mobileStyles.mainTitleFirst)}}>I'm Yalda, a UI/UX designer based in {isMobile ? 'Toronto' : ''}</div></FadeIn>
        <FadeIn timeout={500}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {!isMobile && <div style={{...styles.mainTitleFirst, ...styles.mainTitleSecond, ...(isMobile && mobileStyles.mainTitleSecond)}}>
              Toronto.
            </div>}
            <div style={{ display: 'flex', alignSelf: 'end', paddingBottom: 12, paddingLeft: isMobile ? 0 : 32, paddingTop: isMobile ? 12 : 0}}>
              <TextLink link="#/about-me">Read more</TextLink>
            </div>
          </div>
          </FadeIn>
      </div>
      
      <div style={{ backgroundColor: '#E8E1D6', paddingTop: isMobile ? 60 : 0 }}>
          <div style={{...styles.lineSection, ...(isMobile && mobileStyles.lineSection)}}>
            <div id="projects" style={{...styles.sectionTitle, ...(isMobile && { fontSize: 24 })}}>Selected Projects</div>
            <div style={{...styles.sectionLine, ...(isMobile && mobileStyles.sectionLine)}} />
          </div>
      </div>
      <div style={{ backgroundColor: '#E8E1D6', paddingTop: 0, paddingBottom: 120 }}>
        <div style={{ 
          maxWidth: 1660, margin: '0 auto', alignSelf: 'center' 
        }}>
          {inView1 > 0 && <FadeIn left timeout={200}>
                <ProjectComponent 
                  lock
                  isMobile={isMobile}
                  name="Elentra"
                  link="#/elentra"
                  imagesrc={elentra_home_image} 
                  date='Aug 2022 - Aug 2024' 
                  description='Launched multiple designs to enhance users’ experience on the health sciences education management platform.'
                  role='Lead UX Designer' 
                  type='Full Time'
                />
                  </FadeIn>}
          
          {!isMobile && <div  style={{ height: 82 }}/>}
          <InView onChange={(x) => {if (x) setInView1(inView1+1)}}></InView> 


          {inView1 > 0 && <FadeIn right timeout={200}>
                <ProjectComponent 
                  isMobile={isMobile}
                  name="atmin"
                  link="#/atmin"
                  imagesrc={atmin_home_image} 
                  date='Sept - Nov 2021' 
                  description='Designed an instant notetaking app that saves time by organizing notes and tasks for you.'
                  role='UX Designer' 
                  type='Freelance'
                />
                  </FadeIn>}
          
          {!isMobile && <div  style={{ height: 82 }}/>}
          <InView onChange={(x) => {if (x) setInView1(inView1+1)}}></InView> 
        
          {inView3 > 0 && <FadeIn right timeout={400}>
                <ProjectComponent
                  isMobile={isMobile}
                  name="Off2Class"
                  link="#/off2class"
                  imagesrc={o2c_home_mockup}
                  date='2019 - 2020' 
                  description='Designed branding assets and website to improve user engagement for the ESL lesson content management platform.'
                  role='UI/UX Designer' 
                  type='Full Time'
                />
            </FadeIn>}
          <InView onChange={(x) => {if (x) setInView3(inView3+1)}}></InView>
          </div>
      </div>


      <div style={{...styles.sketchbookSection, ...(width < 1400 && { margin: '100px 100px' }), ...(isMobile && mobileStyles.sketchbookSection)}}>
        <div style={{...styles.sketchbookSectionTextContainer, ...(isMobile && { marginRight: 0, width: '100%', marginBottom: 80})}}>
          <div style={{...styles.sketchbookSectionTitle, ...(isMobile && { fontSize: 24 })}}>
            Want to see more projects?
          </div>
          <div style={{...styles.sketchbookSectionText, ...(isMobile && { fontSize: 16, lineHeight: '1.2rem' })}}>
            Take a look at my collection of various design projects that I’ve worked on in the past!
          </div>
        </div>
        <a href="#/more-projects" style={styles.sketchbookButton} className="circular-button">
          More Projects
        </a>
      </div>
      <Footer /> 
    </div>
  );
}

const styles = { 
  app: { 
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  homeSection: {
    height: '90vh',
    marginLeft: 152,
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 87px 152px", 
    alignItems: 'center'
  },
  name: {
    fontSize: 24,
    fontWeight: 400,
    color: '#623D2E',
    marginBottom: 16,
    paddingTop: '35vh',
  },
  mainTitleFirst: {
    fontFamily: 'Grifo',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 72,
  },
  mainTitleSecond: {
    paddingTop: 24,
  },
  sectionTitle: { 
    fontWeight: 700,
    fontFamily: 'Grifo',
    fontSize: 40,
    color: '#623D2E'
  },
  highlightedProjectTitle: {
    fontFamily: 'Grifo',
    fontSize: 36,
    fontWeight: 700,
    textDecoration: 'none',
    color: '#000',
    marginBottom: 20,
  },
  projectTitle: {
    fontSize: '1.5rem',
    fontWeight: 700
  },
  highlightedProjectSubtitle: {
    fontSize: '1.125rem',
    lineHeight: '1.8rem',
    fontWeight: 400,
    color: '#000000'
  },
  sectionLine: {
    flex: 1,
    height: 0.5,
    backgroundColor: '#623D2E',
    marginLeft: 45,
  },

  sketchbookSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '250px 100px 150px 100px',
  },
  sketchbookSectionTitle: {
    fontSize: 36,
    fontWeight: 700,
    fontFamily: 'Grifo',
    marginBottom: 24,
  },
  sketchbookSectionTextContainer: {
    width: 410,
    marginRight: 120,
  },
  sketchbookSectionText: {
    fontSize: 18,
    lineHeight: '1.8rem'
  },
  sketchbookButton: {
    width: 144,
    height: 144,
    backgroundColor: '#000000',
    fontSize: 16,
    borderRadius: 80,
    color: '#fff',
    textDecoration: 'none',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

const mobileStyles = {
  homeSection: {
    marginLeft: 24,
    height: null,
    marginBottom: 60
  },
  lineSection: { 
    margin: "0px 32px 37px 32px", 
  },
  name: {
    fontSize: 18,
    paddingTop: 152,
  },
  mainTitleFirst: {
    fontSize: 32
  },
  mainTitleSecond: {
    fontSize: 32,
    paddingLeft: 40
  },
  sectionLine: {
    marginRight: -32
  },
  projectTitle: {
    fontSize: '2vh',
    fontWeight: 500
  },
  highlightedProjectSubtitle: {
    fontSize: '1.6vh',
    fontWeight: 400,
    color: '#000000'
  },
  sketchbookSection: {
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'start',
    margin: '100px 36px'
  },
}

export default Home;

