import React from 'react'
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'


import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import o2c_image from '../../assets/o2c-project-image.jpg';

import o2c_userflow_1 from '../../assets/o2c-userflow-1.png';
import o2c_userflow_2 from '../../assets/o2c-userflow-2.png';

import o2c_sketch_1 from '../../assets/o2c-sketch-1.jpg';
import o2c_sketch_2 from '../../assets/o2c-sketch-2.jpg';
import o2c_sketch_3 from '../../assets/o2c-sketch-3.jpg';
import o2c_sketch_4 from '../../assets/o2c-sketch-4.jpg';

import off2class_mockup_video from '../../assets/off2class-mockup-video.mp4';


import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor'

import { CircularButton, NextProjectSection, ProjectTitleSection, SectionTitle } from '../../components/shared-proj-components';

const website =  "https://www.off2class.com/";

function Off2ClassProjectPage() {
  const { isMobile, width } = useIsMobile();

  return (
    <div style={{ backgroundColor: '#fff', position: 'relative', overflow: 'hidden' }} className="app">
      <Header/>
      <div style={{ 
          maxWidth: 1660, margin: '0 auto', alignSelf: 'center' 
        }}>
          <AnimatedCursor
            innerSize={5}
            outerSize={40}
            color='0, 0, 0'
            outerAlpha={0.2}
            innerScale={0.5}
            outerScale={1.5}
          />
          <ProjectTitleSection 
            color={'#000'}
            subtitleColor={'#193935'}
            title={'Off2Class'} 
            subtitle={'WEBSITE DESIGN'} 
            date={'2019 - 2020'} 
            link={isMobile ? '' : 'https://www.off2class.com'}
            description={'An interactive ESL lesson content with the power of student management'} 
            image={o2c_image}
          />
          <SectionTitle title='Design Process' color='#623D2E' />

          <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
            {!isMobile && <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
                {/* <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Introduction</div> */}
            </div>}
            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
              <span>Off2Class is an interactive ESL content tool for teachers and tutors with a student management system, homework, placement test, and games. My role was to design the front end of the website so that it was suitable for students of all ages, teachers, and enterprises, like schools and businesses. <br/><br/></span>
              <span>The first step I took was re-design the logo and come up with a new style guide for the website. Afterwards, I went on to re-vamp the website, starting with the userflow.</span>
            </div>
          </div>
          <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
            <div style={{ display: 'flex', flex: 2, justifyContent: 'space-between', flexDirection: 'column', ...( isMobile && {marginBottom: 40}) }}> 
                <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}></div>
                {!(width < 1400) &&
                <CircularButton 
                  split 
                  borderColor='#000'
                  textColor='#fff'
                  backgroundColor='#000'
                  link={website} 
                  text="View Website" />}
            </div>
            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
              <Zoom><img src={o2c_userflow_1} style={{width: '100%', marginBottom: 24}} alt="off2class userflow 1" /></Zoom>

              <Zoom><img src={o2c_userflow_2} style={{width: '100%', marginBottom: 24}} alt="off2class userflow 1" /></Zoom>

              <span> With their old userflow, many of the pages and buttons would lead back to the homepage for the user to complete. For example, once you click on “Register” it would take you to the homepage to complete the “start a free trial” section where the user would fill out their email. It was very confusing for many users to end up at the homepage after choosing to register. <br/><br/></span>
              <span>The teacher page could only be accessed once you were logged in, so when you clicked on the page to learn more about the lesson library, it was restricted until logged in. For the new userflow, I opted to remove the teachers tab as it did not play a crucial role to users visiting the website with the purpose of learning more. In its place, I designed the resources page that includes tutorials and tips on how to use the platform, which would be beneficial for all users, regardless of whether they have an account. From there, I went on to sketch and wireframe the updated userflow:<br/><br/></span> 
              <div style={{ marginTop: 40, marginBottom: 60, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, flexWrap: 'wrap', ...(isMobile && {marginRight: 0}) }}>
                <Zoom wrapStyle={{ marginRight: 8, marginBottom: 24, width: isMobile ? '100%' : '45%' }}><img src={o2c_sketch_1} style={{width: '100%'}} alt="atmin information architecture diagram" /></Zoom>
                <Zoom wrapStyle={{ marginRight: 8, marginBottom: 24, width: isMobile ? '100%' : '45%' }}><img src={o2c_sketch_2} style={{width: '100%'}} alt="atmin information architecture diagram" /></Zoom>
                {!isMobile && <Zoom wrapStyle={{ marginRight: 8, marginBottom: 24, width: isMobile ? '100%' : '45%' }}><img src={o2c_sketch_3} style={{width: '100%'}} alt="atmin information architecture diagram" /></Zoom>}
                {!isMobile && <Zoom wrapStyle={{ marginRight: 8, marginBottom: 24, width: isMobile ? '100%' : '45%' }}><img src={o2c_sketch_4} style={{width: '100%'}} alt="atmin information architecture diagram" /></Zoom>} 
              </div>
              <span>After sketching, I designed the wireframes for all pages, tested the pages out, re-iterated the design, and started designing high-fidelity prototypes.<br/><br/></span>
              <Zoom>
                <video style={{marginRight: 16, marginTop: 24, width: '100%'}} className='VideoTag' autoPlay playsInline loop muted>
                        <source src={off2class_mockup_video} type='video/mp4'/>
                </video>  
              </Zoom>
            </div>

          </div>
          <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
            <div style={{ display: 'flex', flex: 2, justifyContent: 'space-between', flexDirection: 'column', ...( isMobile && {marginBottom: 40}) }}> 
                <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}></div>
            </div>
            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
              <span>I had quite a lot of fun with this project. I was the only designer on the team and it was nice to experience different roles within the UI/UX position, which gave me a lot of perspective on what I would like to pursue for my career.</span>
              <span><br />A big reason why I left Architecture and pursued UI/UX is this project right here.</span>
              <span><br />Thank you! </span>
              {!!isMobile && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 80, marginBottom: 40}}>
                <CircularButton 
                    split 
                    borderColor='#000'
                    textColor='#fff'
                    backgroundColor='#000'
                    link={website} 
                    text="View Website" />
              </div>}
            </div>
          </div>
          <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#623D2E' />
          <NextProjectSection textColor="#535353" first='elentra' second='atmin' />
          <Footer /> 
        </div>
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    fontFamily: 'Roboto',
    color: '#535353', 
    fontWeight: 700,
    fontSize: 18
  },
  designProcessSubstep: {
    color: '#000000',
    fontSize: 18,
    marginBottom: 16,
  },
  mainBodyText: {
    color: '#000000',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 152px 64px 152px",
      paddingTop: 40,
  },
  subheadings: {
    color: '#193935',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 40px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 600,
    fontSize: 36,
    color: '#623D2E'
  },
  projectTitle: {
    fontSize: 64,
    fontWeight: 500,
    color: '#B59250',
    fontStyle: 'italic',
  },
  projectSubtitle: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 96,
    width: '70%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    margin: "250px 10% 152px 10%",
  },
  projectStepTitle: {
    fontSize: 24,
    color: '#B59250',
    fontWeight: 600
  },
  designProcessStep: {
    height: 50,
    color: '#202020',
    fontSize: 18,
    marginBottom: 16,
    fontWeight: 600,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#623D2E',
    marginLeft: 45,
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 32px 100px 32px",
  },
  titleImageContainer: {
    display: 'flex', width: '95%', justifyContent: 'start'
  },
  lineSection: { 
    margin: "0px 32px 42px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 40px 32px",
  },
  projectStepTitle: {
    fontSize: 18
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#623D2E', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#623D2E', 
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },
}

export default Off2ClassProjectPage;
