import React from 'react'

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import noesis_image from '../../assets/noesis-project-image.png';
import noesis_image_mobile from '../../assets/noesis-project-image-mobile.png';

import noesis_photos_1 from '../../assets/noesis-photos-1.png';
import noesis_photos_2 from '../../assets/noesis-photos-2.png';
import noesis_photos_3 from '../../assets/noesis-photos-3.jpg';
import noesis_photos_4 from '../../assets/noesis-photos-4.jpg';

import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor'


// import off2class_more_proj_image from '../../assets/off2class_more_projects_image.png'

// import naturally_more_proj_image from '../../assets/naturally_more_projects_image.png'
import { NextProjectSection, ProjectTitleSection, SectionTitle } from '../../components/shared-proj-components';


function NoesisProjectPage() {
  const { isMobile } = useIsMobile();

  return (
    <div style={{ backgroundColor: '#fff', overflow: 'hidden' }} className="app">
      <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />
      <Header/>
      <ProjectTitleSection
        color={'#B59250'}
        subtitleColor={'#193935'}
        title={"Noesis"} 
        subtitle={'BOOK COVER DESIGN'} 
        date={'JUNE 2021'} 
        description={'Book cover design for University of Toronto’s undergraduate journal of philosophy.'} 
        image={isMobile ? noesis_image_mobile : noesis_image}
        descriptionWidth={350}
      />
      <SectionTitle title='Design Process' color='#623D2E' />
      
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        {!isMobile && <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
            {/* <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Introduction</div> */}
        </div>}
        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <span>Noesis is an undergraduate journal of philosophy at the University of Toronto. For their 2021 volume, they wanted to explore the theme of what it means to engage in an activist philosophy. Based on their written content, we decided that a minimalist bauhaus design would work best as it resembles the different topics coming together to symbolize activist philosophy. With that in mind, I went ahead and designed different variations of a bauhaus-inspired cover that would be most suitable for their journal this year.<br/><br/></span>
          <br/>
        </div>
      </div>
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
            <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}></div>
        </div>
        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <img src={noesis_photos_1} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />
          <img src={noesis_photos_2} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />
          <img src={noesis_photos_3} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />
          <img src={noesis_photos_4} style={{width: '100%', marginBottom: 24}} alt="oral brooks" />
        </div>
      </div>
     
      <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#623D2E' />
      <NextProjectSection extra first='oral-brooks' second='naturally' textColor='#623D2E' textColorMobile='#623D2E' />
      <Footer /> 
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    fontFamily: 'Roboto',
    color: '#535353', 
    fontWeight: 700,
    fontSize: 18
  },
  designProcessSubstep: {
    color: '#000000',
    fontSize: 18,
    marginBottom: 16,
  },
  mainBodyText: {
    color: '#000000',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 152px 50px 152px",
      paddingTop: 40,
  },
  subheadings: {
    color: '#193935',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 40px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 600,
    fontSize: 36,
    color: '#623D2E'
  },
  projectTitle: {
    fontSize: 64,
    fontWeight: 500,
    color: '#B59250',
    fontStyle: 'italic',
  },
  projectSubtitle: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 80,
    width: '70%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    margin: "250px 152px 100px 152px",
    justifyContent: 'center',
  },
  projectStepTitle: {
    fontSize: 24,
    color: '#B59250',
    fontWeight: 600
  },
  designProcessStep: {
    height: 50,
    color: '#202020',
    fontSize: 18,
    marginBottom: 16,
    fontWeight: 600,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#623D2E',
    marginLeft: 45,
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 24px 100px 24px",
    alignItems: 'center',
  },
  titleImageContainer: {
    display: 'flex', width: '50%', justifyContent: 'center', alignItems: 'center'
  },
  lineSection: { 
    margin: "0px 32px 87px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 40px 32px",
  },
  projectStepTitle: {
    fontSize: 18
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#623D2E', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#623D2E', 
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },
}

export default NoesisProjectPage;
