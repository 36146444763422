import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import GuestArea from '../../pages/guest-area/GuestArea'; // Assuming you have already created this

const ProtectedRoute = ({ children, correctPassword }) => {
  const [authenticated, setAuthenticated] = useState(false);

  const handleAuthentication = (enteredPassword) => {
    if (enteredPassword === correctPassword) {
      setAuthenticated(true);
    } else {
      alert('Incorrect Password');
    }
  };

  if (authenticated) {
    return children; // Render the protected content if authenticated
  }

  // Render GuestArea if not authenticated
  return <GuestArea onAuthenticate={handleAuthentication} />;
};

export default ProtectedRoute;
