import React from 'react';
import useIsMobile from '../../hooks/useIsMobile';

import atmin_project_image from '../../assets/atmin-project-image.png'
import elentra_project_image from '../../assets/elentra/elentra_project_image.png'
import ob_project_image from '../../assets/oral-brooks-project-image.png'
import off2class_project_image from '../../assets/off2class-project-image.png'
import noesis_more_proj_image from '../../assets/noesis_more_projects_image.png'
import naturally_more_proj_image from '../../assets/naturally_more_projects_image.png'
import instacart__more_proj_image from '../../assets/instacart-sketchbook-image.png'
import TextLink from '../text-link';

export const ProjectTitle = ({color, subtitleColor, title, subtitle, description, date, link, descriptionWidth}) => {
    const { isMobile } = useIsMobile();

    return (
        <div style={{  
          display: 'flex',
          flexDirection: 'column', 
          width: '50%',
          justifyContent: 'center', ...(isMobile && { width: '95%' })}}>
          <div style={{ 
              fontSize: 64, 
              color, 
              fontFamily: 'Grifo',
              fontWeight: 700, 
              ...(isMobile && { fontSize: 32})
            }}>{title}</div>
          <div style={{ color: subtitleColor, fontSize: 18, fontWeight: 300, paddingBottom: 5, ...(isMobile && { fontSize: 14})}}>{subtitle}</div>
          <div style={{ color: subtitleColor, fontSize: 18, fontWeight: 300, paddingBottom: 5, ...(isMobile && { fontSize: 14, marginBottom: 40 })}}>{date}</div>
          {!isMobile && <div style={{
              color: subtitleColor,
              fontSize: 18,
              fontWeight: 400,
              marginTop: 64,
              width: descriptionWidth
          }}>
            {description}
          </div>}
          {!!link && 
            <div style={{ display: 'flex', alignSelf: 'start', marginTop: 36}}>
              <TextLink link={link}>View website</TextLink>
            </div>
          }
        </div>
    )
}


export const ProjectTitleSection = ({ 
  color,
  subtitleColor,
  title,
  subtitle,
  date,
  link,
  description,
  image,
  sectionsRef,
  descriptionWidth = 350
 }) => {
  const { isMobile } = useIsMobile();

  return (
    <div id="header" ref={(el) => (sectionsRef ? sectionsRef.current[10] = el : null)} style={{ display: 'flex', margin: "182px 10% 152px 10%", ...(isMobile && 
      {    
        flexDirection: 'column',
        margin: "193px 32px 100px 32px"
      })}}>
      <ProjectTitle
        color={color}
        subtitleColor={subtitleColor}
        title={title}
        subtitle={subtitle}
        date={date}
        link={link}
        description={description}
        descriptionWidth={descriptionWidth}
      />
      <div style={{ 
        justifyContent: 'end', 
        display: 'flex', 
        width: isMobile ? '100%': '50%', 
        ...(isMobile && { justifyContent: 'start' })}}>
          <div>
            <img src={image} style={{   
                zIndex: 1,
                width: isMobile ? '100%' : '90%',
                height: isMobile ? 'auto' : 'auto',
                maxWidth: '100vw',
              }} alt={`${title} mockup`} />
          </div>
      </div>
    </div>
  )
}

export const SectionTitle = ({ color, title }) => {
    const { isMobile } = useIsMobile();

    return (
        <div style={{ 
            display: 'flex', 
            margin: "0px 0px 87px 10%", 
            alignItems: 'center', 
            ...(isMobile && { margin: "0px 32px 87px 32px" })}}>
            <div style={{
                fontWeight: 700,
                fontSize: '2.5rem',
                fontFamily: 'Grifo',
                color, 
                ...(isMobile && { fontSize: 24 })}}>
                    {title}
            </div>
            <div style={{
                flex: 1,
                height: 1,
                backgroundColor: color,
                marginLeft: 45, 
                ...(isMobile ? { marginRight: -32 } : { marginRight: -500 })}} />
        </div> 
    )
}


export const SubsectionTitle = ({ title, color, lineColor }) => {
    const { isMobile } = useIsMobile();

    return (
        <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
            <div style={{
                fontSize: 32,
                color,
                fontWeight: 700, 
                fontFamily: 'Grifo',
                width: '80%',
                ...(isMobile && { fontSize: 24, width: null })}}>{title}</div>
            {!!isMobile && <div style={{
                flex: 1,
                height: 1,
                backgroundColor: lineColor,
                marginLeft: 45, 
                ...(isMobile && { marginRight: -32 })}} />}
        </div>
    )
}

export const NextProjectComponent = ({ title, link, subtitle, image, imageMobile, color, colorMobile, extra}) => {
    const { isMobile } = useIsMobile();

    return (
        <a href={link} style={{ flex: isMobile ? '1 1 100%' : '1 1 40%', textDecoration: 'none', color: 'black' }}>
            <div style={isMobile ? { width: '100%', display: 'flex', justifyContent: 'center'} : {}}>
                <img src={isMobile ? imageMobile : image} style={{ borderRadius: extra ? 5 : 0, width: '100%', marginBottom: isMobile ? 12 : 26, marginLeft: isMobile || extra ? 0 : '-15%'}} alt={`${title} mockup`} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', ...(isMobile && {flexDirection: 'row'})}}>
                <div style={{
                    color, //'#979797', 
                    fontWeight: 700,
                    fontSize: 18, 
                    ...(isMobile && 
                        {
                            color: colorMobile,// '#E0BE74', 
                            fontWeight: 700,
                            fontSize: 14,
                            marginRight: 4
                        }
                    )}}>{title}</div>
                <div style={{
                  color, 
                  fontSize: 14, 
                  ...(isMobile && {
                    color: colorMobile,
                  })}}>{isMobile ? `|  ${subtitle}` : subtitle}</div>
            </div>
        </a>
    )
}

export const NextProjectSection = ({first, second, textColor, textColorMobile, extra}) => {
  const { isMobile } = useIsMobile();

  const mapper = {
    'elentra': {
      color: textColor ?? '#535353',
      colorMobile: textColorMobile ?? '#623D2E',
      title:'Elentra', 
      subtitle:'Product Design', 
      link:'#/elentra', 
      image: elentra_project_image, 
      imageMobile: elentra_project_image
    },
    'atmin': {
      color: textColor ?? '#535353',
      colorMobile: textColorMobile ?? '#623D2E',
      title:'atmin', 
      subtitle:'UX Design', 
      link:'#/atmin', 
      image: atmin_project_image, 
      imageMobile: atmin_project_image
    },
    'off2class': {
      color: textColor ?? '#535353',
      colorMobile: textColorMobile ?? '#623D2E',
      title:'off2class',
      subtitle:'Website',
      link:'#/off2class', 
      image: off2class_project_image,
      imageMobile: off2class_project_image
    },
    'oral-brooks': {
      color: textColor ?? '#535353',
      colorMobile: textColorMobile ?? '#623D2E', 
      title: 'Oral Brooks',
      subtitle: 'Website Design',
      link: '#/oral-brooks',
      image: ob_project_image,
      imageMobile: ob_project_image
    },
    noesis: {
      image: noesis_more_proj_image,
      imageMobile: noesis_more_proj_image,
      link: '#/noesis',
      title: 'Noesis',
      subtitle: 'Branding',
      color: textColor,
      colorMobile: textColorMobile, 
    },
    naturally: {
      image: naturally_more_proj_image,
      imageMobile: naturally_more_proj_image,
      link: '#/naturally',
      title: "Naturally D'vine",
      subtitle: 'Product Photography',
      color: textColor,
      colorMobile: textColorMobile, 
    },
    instacart: {
      image: instacart__more_proj_image,
      imageMobile: instacart__more_proj_image,
      link: '#/instacart',
      title: "Instacart",
      subtitle: 'Casestudy',
      color: textColor,
      colorMobile: textColorMobile, 
    }
  }

  return (
      <div style={{...style.stepContainer, margin: "0px 152px 60px 152px", ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center'}) }} />
        <div style={{ display: 'flex', flex: 4, flexDirection: 'row', flexWrap: 'wrap', ...style.atminIntroductionText}}>
            {!isMobile && <div style={{ width: '0%' }} />}
            <NextProjectComponent
              extra={extra}
              color={mapper[first].color} 
              colorMobile={mapper[first].colorMobile} 
              title={mapper[first].title} 
              subtitle={mapper[first].subtitle} 
              link={mapper[first].link}
              image={mapper[first].image} 
              imageMobile={mapper[first].imageMobile}  />
            {!isMobile && <div style={{ width: '15%' }} />}
            {!isMobile && 
            <NextProjectComponent 
              extra={extra}
              color={mapper[second].color} 
              colorMobile={mapper[second].colorMobile} 
              title={mapper[second].title} 
              subtitle={mapper[second].subtitle} 
              link={mapper[second].link}
              image={mapper[second].image} 
              imageMobile={mapper[second].imageMobile}  
            />}
        </div>
    </div>
  )
}

export const CircularButton = ({ link, text, split, borderColor, textColor, backgroundColor}) => {
  return (<a href={link} target="_blank" rel="noreferrer" className="circular-button" 
            style={{ width: 144,
                height: 144,
                border: `1px solid ${borderColor}`,
                backgroundColor: backgroundColor,
                borderRadius: '50%',
                color: textColor,
                textDecoration: 'none',
                fontSize: 18,
                fontFamily: 'Roboto',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'}}>
                  {split ? text.split(" ")[0] : text}
                  {split ? <br /> : ''}
                  {split ? text.split(" ")[1] : ''}
          </a>)
}


const style = {
    nextProjectSubtitle: {
      color: '#979797', 
      fontSize: 14
    },
    nextProjectTitle: {
      color: '#979797', 
      fontWeight: 700,
      fontSize: 18
    },
    atminIntroductionText: {
      fontSize: 18,
      color: '#DADADA',
      fontWeight: 400
    },
    stepContainer: {
        display: 'flex',
        margin: "0px 152px 140px 152px",
    },
    mintText: {
      color: '#80F3CA',
      fontSize: 18
    },
    bold: {
      fontWeight: 600
    },
    lineSection: { 
      display: 'flex', 
      margin: "0px 0px 87px 152px", 
      alignItems: 'center'
    },
    sectionTitle: { 
      fontWeight: 700,
      fontSize: '2.5rem',
      fontFamily: 'Grifo',
      color: '#E0BE74'
    },
    projectTitle: {
      fontSize: 64,
      color: '#E0BE74',
      fontStyle: 'italic',
      fontWeight: 500,
    },
    projectSubtitle: {
      color: 'white',
      fontSize: 18,
      fontWeight: 300,
      paddingBottom: 5,
    },
    projectDescription: {
      color: 'white',
      fontSize: 18,
      fontWeight: 400,
      marginTop: 96,
      width: '50%'
    },
    projectTitleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      display: 'flex',
      margin: "152px 152px 100px 152px",
      justifyContent: 'center',
    },
    projectStepTitle: {
      fontSize: 24,
      color: '#B59250',
      fontWeight: 600
    },
    designProcessStep: {
      height: 50,
      color: '#80F3CA',
      fontSize: 18,
      marginBottom: 16,
      width: 120,
    },
    sectionLine: {
      flex: 1,
      height: 1,
      backgroundColor: '#E0BE74',
      marginLeft: 45,
    },
    button: { width: 144,
      height: 144,
      border: '1px solid #E0BE74',
      backgroundColor: '#E0BE74',
      borderRadius: '50%',
      color: '#000',
      textDecoration: 'none',
      fontSize: 18,
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    button_alternate: { 
      width: 144,
      height: 144,
      border: '1px solid #E0BE74',
      borderRadius: '50%',
      color: '#E0BE74',
      textDecoration: 'none',
      fontSize: 18,
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
  
const mobileStyle = {
    container: {
      flexDirection: 'column',
      margin: "193px 24px 100px 24px",
      alignItems: 'center',
    },
    titleImageContainer: {
      display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'
    },
    lineSection: { 
      margin: "0px 32px 87px 32px", 
    },
    stepContainer: {
      display: 'flex',
      margin: "0px 32px 87px 32px",
    },
    projectStepTitle: {
      fontSize: 18
    },
    sectionLine: {
      marginRight: -32
    },
    nextProjectSubtitle: {
      color: '#E0BE74', 
      fontSize: 14
    },
    nextProjectTitle: {
      color: '#E0BE74', 
      fontWeight: 700,
      fontSize: 14,
      marginRight: 4
    },
}