import React, { useRef } from 'react'

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import elentra_project_image from '../../assets/elentra/elentra-project-image.png';

import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

import elentra_persona_1 from '../../assets/elentra/P_ Barnes.png';
import elentra_persona_2 from '../../assets/elentra/P_ Super User.png';
import elentra_persona_3 from '../../assets/elentra/P_ Jess.png';
import elentra_persona_4 from '../../assets/elentra/P_ Frank.png';

import elentra_information_architecture from '../../assets/elentra/IA.png';
import elentra_task_flow from '../../assets/elentra/task-flow.png';
import usability_testing from '../../assets/elentra/usability-testing.png';

import mid_fidelity from '../../assets/elentra/mid-fidelity.mp4';
import task_1_video from '../../assets/elentra/task-1.mp4';
import task_2_video from '../../assets/elentra/task-2.mp4';

import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';

import AnimatedCursor from '../../components/animated-cursor/animated-cursor'
import { NextProjectSection, ProjectTitleSection, SectionTitle, SubsectionTitle } from '../../components/shared-proj-components';
import SideNav from '../../components/side-nav/SideNav';

function InstacartProjectPage() {
  const { isMobile, width } = useIsMobile();
  const sections = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'research', title: 'Research' },
    { id: 'ia', title: 'Information Architecture' },
    { id: 'wireframing', title: 'Wireframing' },
    { id: 'design', title: 'UI Design' },
    { id: 'results', title: 'Results' }
  ];

  const sectionsRef = useRef([]);

  return (<div style={{ backgroundColor: '#FFFFFF', position: 'relative', overflow: 'hidden' }}>
          <SideNav items={sections} textColor={'#065B85'} sectionsRef={sectionsRef} />
          <Header/>
      <div style={{ 
        maxWidth: 1660, margin: '0 auto', alignSelf: 'center' 
      }} className="app">
        <AnimatedCursor
          innerSize={5}
          outerSize={40}
          color='0, 0, 0'
          outerAlpha={0.2}
          innerScale={0.5}
          outerScale={1.5}
        />
        <ProjectTitleSection 
          color={'#065B85'}
          subtitleColor={'#000000'}
          title={'Elentra'} 
          subtitle={'LEAD UX DESIGNER'} 
          date={'AUG 2022 - AUG 2024'} 
          description={<span>Launched <b><i>Manage Portfolio</i></b> to enhance users' experience on the health sciences education management platform.</span>} 
          image={elentra_project_image}
          sectionsRef={sectionsRef}
        />
        <SectionTitle title='Design Process' color='#065B85' />
        <div style={{...style.stepContainer, ...(isMobile && mobileStyle.stepContainer)}}>
          {!isMobile && <div style={{ display: 'flex', flex: 2 }}>
            {/* <a href={prototype_link} target="_blank" rel="noreferrer" className="circular-button" 
              style={{ width: 144,
                  height: 144,
                  border: '1px solid #000000',
                  backgroundColor: '#000000',
                  borderRadius: '50%',
                  color: '#fff',
                  textDecoration: 'none',
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'}}>
              View< br />Prototype
            </a> */}
          </div>}
          
          <div style={{ display: 'flex', flexWrap: 'wrap', flex: 4, rowGap: 24 }}>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
              <div style={{ color: '#065B85', fontWeight: '800' }} className="design-process-step design-process-number">1.</div>
              <div>
                <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Research</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>User interviews</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Personas</div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row' }}>
            <div style={{ color: '#065B85', fontWeight: '800' }} className="design-process-step design-process-number">2.</div>
            <div>
                <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Information Architecture</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>IA</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Task flows</div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
            <div style={{ color: '#065B85', fontWeight: '800' }} className="design-process-step design-process-number">3.</div>
            <div>
                <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Wireframes</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Mid-fidelity</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Testing</div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row' }}>
              <div style={{ color: '#065B85', fontWeight: '800' }} className="design-process-step design-process-number">4.</div>
              <div>
                <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>UI Design</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>High Fidelity</div>
                <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Results</div>
              </div>
            </div>
            {/* {!!isMobile && <div style={{ width: '100%', marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40}}>
            <a href={prototype_link} target="_blank" rel="noreferrer"  className="circular-button" 
                style={{ width: 144,
                      height: 144,
                      border: '1px solid #000',
                      backgroundColor: '#000',
                      borderRadius: '50%',
                      color: '#fff',
                      textDecoration: 'none',
                      fontSize: 18,
                      fontFamily: 'Roboto',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'}}>
                        View<br/>Prototype
                      </a>
              </div>} */}
          </div>
        </div>
        
        <div id="introduction" ref={(el) => (sectionsRef.current[5] = el)}  style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          <SubsectionTitle title='Introduction' color='#065B85' lineColor='#065B85' />        

          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
            <span>Our B2B clients had made it clear to the product team that the module, <b><i>Manage Portfolio</i></b>, needed an immediate refresh to support their various functionalities for their upcoming year. I was the lead UX designer for this project and collaborated closely with both the product manager and product owner to research, design, test and finally launch the enhanced module.<br/><br/></span>
            <span>Where the old module failed to meet our clients and therefore users needs were in these 3 areas: <br/><br/></span>
            <ul style={{ listStyleType: 'disc', marginLeft: isMobile ? 24 : 64 }}>
                  <li style={{ marginBottom: 8 }}><b>Limited Audience Selection:</b> Administrators were limited in their selections to only cohorts of learners which did not accommodate the needs of many undergraduate medical programs that require flexibility for offcycle learners.  </li>
                  <li style={{ marginBottom: 8 }}><b>Time Consuming:</b> Admins manage hundreds of learners and numerous faculty members, and without the ability to perform bulk actions and rollovers, setting up multiple portfolios with their associated tasks quickly became a frustrating and time-consuming process.</li>
                  <li style={{ marginBottom: 8 }}><b>No Re-submissions:</b> After reviewing learner submissions, advisors were unable to request revisions, preventing learners from improving their initial tasks. This led to frustration for both advisors and learners.</li>
            </ul>
            <br/>
          </div>
        </div>
      
        <div id="research" ref={(el) => (sectionsRef.current[0] = el)}  style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          <SubsectionTitle title='Research' color='#065B85' lineColor='#065B85' />        

          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>User Interviews</div>}
            <span>I met with clients who expressed interest in an enhanced Manage Portfolio module and arranged interviews with several administrators who oversee portfolio management. The objectives of these interviews were to:<br/></span>
  
            <ul style={{ marginBottom: 48, listStyleType: 'disc', marginLeft: isMobile ? 24 : 64 }}>
                  <li style={{ marginBottom: 8 }}>Understand the root causes of user frustration with the current module.</li>
                  <li style={{ marginBottom: 8 }}>Identify feature gaps within the system.</li>
                  <li style={{ marginBottom: 8 }}>Gain detailed insights into the purpose and functionality of the requested features.</li>          
            </ul>
            {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 40 }}>Personas</div>}
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5%', rowGap: 24, maxWidth: 700 }}>
              <Zoom style={{width: isMobile ? '95%' : '45%' }}><img src={elentra_persona_1} style={{width: '100%', maxWidth: 313 }} alt="persona 1" /></Zoom>
              <Zoom style={{width: isMobile ? '95%' : '45%' }}><img src={elentra_persona_2} style={{width: '100%', maxWidth: 313 }} alt="persona 2" /></Zoom>
              <Zoom style={{width: isMobile ? '95%' : '45%' }}><img src={elentra_persona_3} style={{width: '100%', maxWidth: 313 }} alt="persona 3" /></Zoom>
              <Zoom style={{width: isMobile ? '95%' : '45%' }}><img src={elentra_persona_4} style={{width: '100%', maxWidth: 313 }} alt="persona 4" /></Zoom>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 40 }}>
          <div  id="ia"  ref={(el) => (sectionsRef.current[1] = el)} style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
            <SubsectionTitle title='Information Architecture' color='#065B85' lineColor='#065B85' />        

            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
              {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>IA</div>}
              <span>After identifying the users' pain points, the product owner and I began revising the information architecture with the specific goal of finding opportunities to integrate new features while enhancing the overall user flow.</span>
              
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40, ...(!isMobile && {marginRight: -100}) }}>
                <div style={{ flex: 1, marginRight: 24}}>
                  <Zoom><img src={elentra_information_architecture} style={{width: '95%'}} alt="elentra information architecture" /></Zoom>
                </div>
              </div>
              <div style={{ ...style.subheadings, ...style.bold, marginBottom: 12, marginTop: 64 }}>Task Flow</div>
              <span>Patricia wants to create a new portfolio titled PGFM and attach PGY1 Cohort as the audience:</span>
              <div style={{ marginBottom: 52, display: 'flex', flex: 1, flexDirection: 'row', marginTop: 24, marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32})}}>
                <Zoom><img src={elentra_task_flow} style={{marginRight: 8, width: '95%'}} alt="task flow - elentra" /></Zoom>
              </div>
            </div>
          </div>

        
          <div id="wireframing" ref={(el) => (sectionsRef.current[2] = el)} style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
            {/* <div style={{ display: 'flex', flex: width < 1400 ? 1 : 2, ...( isMobile ? {alignItems: 'center', marginBottom: 40 } : { flexDirection: 'column', justifyContent: 'space-between'}) }}> 
              <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Wireframing</div>
            </div> */}
            <SubsectionTitle title='Wireframing' color='#065B85' lineColor='#065B85' />        

            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>            
              <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>Mid-fidelity</div>
              <span>I proceeded to create mid-fidelity mock-ups of the pages, using the initial module as a foundation. My goal was to design mock-ups that were similar enough to the current product in order to conduct usability testing. Here are the mock-ups for usability session 1:</span>
              <Zoom>
                <video style={{marginRight: 8, marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '100%'}} className='VideoTag' autoPlay playsInline loop muted>
                      <source src={mid_fidelity} type='video/mp4'/>
                </video>  
              </Zoom>
              <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>Usability Testing</div>
              <span>I tested the prototype with 4 of our super users from various roles who have extensive knowledge of the product. I prepared 5 tasks for them to complete and tracked the error frequency rate to prioritize issues for resolution. The results were gathered after 2 usability sessions, totalling 8 interviews:</span>
              <Zoom><img src={usability_testing} style={{marginRight: 8, width: '95%'}} alt="task flow - elentra" /></Zoom>

              {/* <span style={{  }}>Key Takeaways<br/><br/></span> */}
              <ul style={{ marginLeft: isMobile ? 24 : 64 }}>
                    <li style={{ marginBottom: 8, fontWeight: 600, marginTop: 48 }}>Key Takeaways</li>
              </ul>
              <ul style={{ listStyleType: 'disc', marginLeft: isMobile ? 24 : 64, maxWidth: 700 }}>
                    <li style={{ marginBottom: 12 }}>Importing Course Groups from Manage Courses eliminated the need for users to recreate them in Portfolios, simplifying a previously cumbersome process.</li>
                    <li style={{ marginBottom: 12 }}>The original modal setup was time-consuming, especially when creating folders and adding tasks one by one. Implementing a wizard design streamlined this process and resolved many of these issues.</li>
                    <li style={{ marginBottom: 12 }}>I validated my hypothesis that folders were primarily used for task organization, leading to the introduction of the “Task Label” feature, which was well-received by users.</li>
                    <li style={{ marginBottom: 12, marginLeft: 48 }}>With the Task Label feature, users could view all tasks at the portfolio level while still maintaining organization. The key advantage was that users could now track learner progress directly from the portfolio view, eliminating the need to navigate through individual folders, ultimately saving them significant time.
                    </li>
              </ul>
            </div>

          </div>
          <div id="design" ref={(el) => (sectionsRef.current[3] = el)} style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
            <SubsectionTitle title='UI Design' color='#065B85' lineColor='#065B85' />        

            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>            
              <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>High-fidelity</div>
              <span>Create a portfolio</span>
              <Zoom>
                <video style={{marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '100%'}} className='VideoTag' autoPlay playsInline loop muted>
                      <source src={task_1_video} type='video/mp4'/>
                </video>  
              </Zoom>

              <span>Create tasks</span>
              <Zoom>
                <video style={{marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '100%'}} className='VideoTag' autoPlay playsInline loop muted>
                      <source src={task_2_video} type='video/mp4'/>
                </video>  
              </Zoom>
            </div>

          </div>
          
          <div id="results" ref={(el) => (sectionsRef.current[4] = el)} style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>     
            <SubsectionTitle title='Results' color='#065B85' lineColor='#065B85' />

            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
              <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>Client Feedback</div>
              <ul style={{ listStyleType: 'disc', marginLeft: 24, maxWidth: 700 }}>
                    <li style={{ marginBottom: 12 }}>I conducted a demo with over 10 universities, with majority of clients showing strong interest in having the solution integrated in their production environments.</li>
                    <li style={{ marginBottom: 12 }}>Received positive feedback confirming that we have effectively met client requirements.</li>
              </ul>
              <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>KPI's to Track</div>
              <span>After launching the module this year, we monitored the following key metrics:</span>
              <ul style={{ listStyleType: 'disc', marginLeft: 24, maxWidth: 700 }}>
                    <li style={{ marginBottom: 12 }}>The number of universities that enabled the module in their production environment.</li>
                    <li style={{ marginBottom: 12 }}>User activity within the module, specifically:</li>
                      <li style={{ marginBottom: 12, marginLeft: 48 }}>The number of portfolios created per university.</li>
                      <li style={{ marginBottom: 12, marginLeft: 48  }}>The rate of tasks assigned versus tasks completed by learners.</li>
                      <li style={{ marginBottom: 12, marginLeft: 48  }}>The number of incomplete tasks, which signals potential issues that require investigation.</li>
                    <li style={{ marginBottom: 12 }}>After one year, how many portfolios were rolled over to the new academic year.</li>
              </ul>
            </div>
          </div>
          <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#623D2E' />
          <NextProjectSection extra first='atmin' second='off2class' textColor='#623D2E' textColorMobile='#623D2E' />
          <Footer /> 
        </div>
      </div>
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    fontFamily: 'Roboto',
    color: '#535353', 
    fontWeight: 700,
    fontSize: 18
  },
  designProcessSubstep: {
    color: '#000000',
    fontSize: 18,
    marginBottom: 16,
  },
  mainBodyText: {
    color: '#000000',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 10% 100px 10%",
  },
  subheadings: {
    color: '#065B85',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 87px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 600,
    fontSize: 36,
    color: '#065B85'
  },
  projectTitle: {
    fontSize: 64,
    color: '#4DAB33',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  projectSubtitle: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 96,
    width: '70%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    margin: "250px 152px 100px 152px",
    justifyContent: 'center',
  },
  projectStepTitle: {
    fontSize: 32,
    color: '#065B85',
    fontWeight: 700,
    fontFamily: 'Grifo',
  },
  designProcessStep: {
    height: 50,
    color: '#065B85',
    fontSize: 18,
    fontWeight: 800,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#065B85',
    marginLeft: 45,
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 24px 100px 24px",
    alignItems: 'center',
  },
  titleImageContainer: {
    display: 'flex', width: '50%', justifyContent: 'center', alignItems: 'center'
  },
  lineSection: { 
    margin: "0px 32px 87px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 87px 32px",
  },
  projectStepTitle: {
    fontSize: 24
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#535353', 
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },
}

export default InstacartProjectPage;
